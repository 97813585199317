import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { api } from './services/api';

import vmsReducer from '../features/vms/vmsSlice';
import publicCloudReducer from '../features/public-cloud/publiccloudSlice';

import jobReducer from '../features/jobs/jobsSlice';

import authReducer from '../features/auth/authSlice';
import usersReducer from '../features/users/usersSlice';

import tableReducer from '../common/components/table/tableSlice';

export const baseKeycloakUrl = 'https://identity.partitio.com/realms/admin-si-vital';
// export const baseApiUrl = process.env.REACT_APP_NODE_ENV === 'development' ? process.env.REACT_APP_DEV_API_BASE_URL : process.env.REACT_APP_API_BASE_URL;
export const baseApiUrl = 'https://dashboard.partitio-si-vital.ovh/api';
// export const baseFrontUrl = process.env.REACT_APP_NODE_ENV === 'development' ? process.env.REACT_APP_DEV_FRONT_BASE_URL : process.env.REACT_APP_FRONT_BASE_URL;
export const baseFrontUrl = 'https://dashboard.partitio-si-vital.ovh';

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,

    auth: authReducer,
    users: usersReducer,
    vms: vmsReducer,
    publicCloud: publicCloudReducer,
    jobs: jobReducer,

    table: tableReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
